import React from "react";
import logo from "./logo.png";
import styles from "./App.module.scss";


const App = () => {
	return (
		<div className={styles.App}>
			<header>
				<img src={logo} alt="gacko.pl" />
			</header>
			<main>
				<h1>Witaj na gacko.pl!</h1>
				<p>gacko.pl to domowy serwer służący jego administratorom do hostowania i publikowania w internecie różnych serwerów, stron, baz danych oraz innych usług.</p>
				<p>
					Zapraszamy na:
					<ul>
						<li>
							Samodzielnie hostowaną instancję GitLaba - <a href="https://git.gacko.pl">git.gacko.pl</a>
						</li>
						<li>
							Serwer Minecraft - <code>rafciocraft.gacko.pl</code>
						</li>
						<li>
							Serwer deweloperski Polsko-Angielskiego Słownika Biologicznego <a href="https://biodictionary.inf.ug.edu.pl">biodictionary.inf.ug.edu.pl</a> - <a href="https://biodictionary.dev.ug.gacko.pl">biodictionary.dev.ug.gacko.pl</a>
						</li>
					</ul>
				</p>
			</main>
		</div>
	);
};

export default App;
